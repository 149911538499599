.image-gallery-thumbnails {
  display: flex;
}
.reviews,
.snaps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.reviews {
  width: 90%;
}

.snaps {
  width: 5%;
}
