body {
  overscroll-behavior: none;
}

html,
body {
  position: fixed;
  overflow: hidden;

  margin: 0; /* remove default margin */
  padding: 0; /* remove default padding */
  width: 100%; /* take full browser width */
  height: 100%;
  min-height: -webkit-fill-available;
}

.content {
  flex: 1;
}

.App {
  font-family: sans-serif;
}
/* Fixes image not displayed in carosel */
.image-gallery-thumbnail-inner img {
  display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(240, 240, 240);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

h5,
h6 {
  font-weight: unset;
}

.grecaptcha-badge {
  display: none;
}
