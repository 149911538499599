.facebook-login-button {
  width: 100%;
  height: 38px;
  background-color: #4c69ba;
  color: white;
  text-align: left;
  border-radius: 0.25rem;
}

.facebook-login-button i {
  float: right;
  margin-top: 5px;
  margin-right: 5vw;
}
